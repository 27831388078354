<template>
  <BaseButton2 @click="(event) => openModal(modal, event)">
    <slot />
  </BaseButton2>
</template>

<script lang="ts" setup>
interface Props {
  modal: string;
}

defineProps<Props>();

const { openModal } = useModalStore();
</script>
